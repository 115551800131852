<template>
  <div class="bonus__page">
    <simplebar class="preview-scroll">
      <WorkspaceHeader />
      <div class="bonus__page-overlay"></div>
      <div class="bonus__page-view">
        <div class="">
          <div class="bonus__page-banner">
            Join our 6 Weeks Mentorship & $5,000 Revenue Challenge!
            <span class="tag"></span>
          </div>
          <div class="bonus__page-video my-5">
            <!-- <iframe
              width="100%"
              height="345"
              src="https://www.youtube.com/embed/GAa2UlVYb6E?rel=0"
              frameborder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              loading="easy-loading"
            ></iframe> -->
            <img src="../../../assets/images/roadmaps/mentorship.png" alt="Mentorship" />
          </div>
          <br />
          <a href="https://aisocialagents.net/go/join-mentorship" target="_blank" class="mb-5 bonus__page-button">
            Click Here to Join the Group
          </a>
        </div>
      </div>
    </simplebar>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import WorkspaceHeader from '~/components/Dashboard/WorkspaceHeader.vue';
import { FETCH_WORKSPACES_QUERY } from '~/graphql/queries';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

const accessControl = createNamespacedHelpers('accessControl');

export default {
  components: {
    simplebar,
    WorkspaceHeader,
  },

  data() {
    return {
      fetchWorkspaces: [],
    };
  },

  apollo: {
    fetchWorkspaces: {
      query: FETCH_WORKSPACES_QUERY,
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authBusy: state => state.auth.busy,
      showTutorialModal: state => state.auth.user.options.onboarding.homepage,
    }),

    ...accessControl.mapGetters(['isUserReseller', 'isUserAdminInWorkspace', 'isUserClientInWorkspace']),
  },

  created() {},
  methods: {
    //
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';
.bonus__page {
  background-image: url('../../../assets/images/bonus_bg.svg');
  height: 100%;
  &-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-image: url('../../../assets/images/bonus_ring.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 75%;
    z-index: -1;
  }
  &-banner {
    background-image: url('../../../assets/images/bonus_banner_bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: 20px 40px;
    border-right: 20px;
    font-weight: 900;
    color: black;
    position: relative;
    .tag {
      position: absolute;
      top: -25px;
      left: -10px;
      width: 40px;
      content: url('../../../assets/icons/flame.svg');
    }
  }
  &-button {
    background: #52b788;
    color: #ffffff;
    padding: 20px 40px;
    border: 2px solid #ffffff;
    border-radius: 12px;
    box-shadow: rgba(119, 244, 115, 0.56) 0px 22px 70px 4px;
    &:hover {
      background: #39bb7e;
      color: #ffffff;
      text-decoration: none;
      box-shadow: rgba(63, 253, 57, 0.56) 0px 22px 70px 4px;
    }
  }
  &-video {
    background-color: $gray-200;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    overflow: hidden;
    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }
  &-view {
    padding-top: $navbar-height;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    vertical-align: middle;
    z-index: 1;
  }
  .preview-scroll {
    // min-height: 100vh;
    overflow-y: auto;

    .simplebar-scrollbar {
      width: 5px;
    }

    .simplebar-scrollbar:before {
      border-radius: 5px;
      background: rgba($primary, 0.7);
    }

    .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
      /* When hovered, remove all transitions from drag handle */
      opacity: 1;
      transition: opacity 0s linear;
    }
  }
}
</style>
